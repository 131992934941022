@import url(https://fonts.googleapis.com/css?family=Open+Sans:300, 400);
.story--md {
  
}
textarea {
  min-height: 75px !important;
}

.avatar {
  display: inline-block !important;
  height: 30px !important;
  width: 30px !important;
  margin-right: 5px !important;
}

.chart {
    height: 100%;
    max-height: 100%;
    padding: 0 10px 0 10px;
}

svg {
    overflow: visible !important;
}

g>rect {
    cursor: pointer;
}

g>rect:hover {
    -webkit-filter: brightness(0.75);
            filter: brightness(0.75)
}

g>rect:active {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5)
}

.sprint-name {
    cursor: pointer
}

.sprint-name:hover {
    font-weight: bold;
}

.sprint-name:active {
    fill: black
}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: rgb(52, 69, 99);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142857143;
}

html {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

*,
*:before,
*:after {
  outline: 0;
}

hr {
  border: none;
  border-top: 1px solid #F4F5F7;
}


.dc-fine-print {
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  list-style-type: none;
  list-style-position: outside;
  list-style-position: initial;
  list-style-image: none;
  list-style-image: initial;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  color: rgba(255, 255, 255, 0.8);
}

.dc-fine-print > li + li::before {
  content: "•";
  margin-top: 0px;
  margin-right: 8px;
  margin-bottom: 0px;
  margin-left: 8px;
}

.dc-fine-print a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.dc-fine-print a:hover {
  text-decoration: underline;
}

.search-input {
  position: relative;
}

.search-input span {
  display: block;
  content: "Drücke Ⓕ um zu filtern";
  position: absolute;
  right: 1em;
  top: 0.8em;
  color: white;
}

.search-input input:focus + span {
  display: none;
}

.helper-text .only-active {
  opacity: 0;
}

.search-input input:focus ~ .helper-text .only-active {
  opacity: 1;
}

