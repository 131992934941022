.chart {
    height: 100%;
    max-height: 100%;
    padding: 0 10px 0 10px;
}

svg {
    overflow: visible !important;
}

g>rect {
    cursor: pointer;
}

g>rect:hover {
    filter: brightness(0.75)
}

g>rect:active {
    filter: brightness(0.5)
}

.sprint-name {
    cursor: pointer
}

.sprint-name:hover {
    font-weight: bold;
}

.sprint-name:active {
    fill: black
}

