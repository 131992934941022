textarea {
  min-height: 75px !important;
}

.avatar {
  display: inline-block !important;
  height: 30px !important;
  width: 30px !important;
  margin-right: 5px !important;
}
